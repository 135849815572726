interface mediaData {
  original: string
  thumb: string
  medium: string
  large: string
}

export type TBadge = {
  id?: number
  order: number
  is_active: boolean
  has_count_down: boolean
  is_border_blinking: boolean
  theme: string
  media: Record<string, mediaData> | {}
  contents: Array<{
    language: string
    name: string
  }>
  icon: Record<string, mediaData> | {}
}

export const initialBadge: TBadge = {
  order: 1,
  is_active: true,
  has_count_down: false,
  is_border_blinking: false,
  theme: '',
  media: {},
  icon: {},
  contents: [
    {
      language: 'tr',
      name: '',
    },
    {
      language: 'ar',
      name: '',
    },
    {
      language: 'en',
      name: '',
    },
  ],
}

export interface IBadge {
  showManageModal: boolean
  showDeleteModal: boolean
  showFilterDrawer: boolean
  badgesList: Array<TBadge>
  badge: any
  badgesFilterOptions: Array<any>
}

export interface IBadgeObject {
  badges: IBadge
}

export const initialState: IBadge = {
  showManageModal: false,
  showDeleteModal: false,
  showFilterDrawer: false,
  badgesList: [],
  badge: initialBadge,
  badgesFilterOptions: [],
}
